@use 'sass:map';
// colors
$primary_white: #ffffff;
$primary_red: #e00000;
$primary_blue: #001996;
$primary_dark_blue: #001371;
$primary_dark_grey: #6c757d;
$secondary_light_blue: #00a2c7;
$secondary_blue: #1617c5;
$secondary_grey: #495057;
$secondary_light_grey: #a2aaad;
$secondary_orange: #7e5f2b;
$secondary_yellow: #fbdd40;
$secondary_green: #61a30a;
$secondary_magenta: #c800a1;
$secondary_black: #000000;
$secondary_red: #da322a;
$secondary_icon_grey: #64a70b;
$web_yellow: #f8c81e;
$web_light_grey: #d3d3d3;
$web_lighter_grey: #f5f6f6;
$web_dark_grey: #495057;
$web_grey: #c0c0c0;
$web_text_black: #111111;
$web_text_light: #ffffff;
$text_link: #001996;
$text_link_hover: #0a38e6;
$dropdown_hover_light_blue: #ebf0fd;
$translucent_light_blue: #00a9ce59;
$light_shade_primary_blue: #00199680;
$invalid_feedback: #f00000;
// end of colors
$primary: $primary_blue;
$link-hover-color: $text_link_hover;
$btn-link-disabled-color: $secondary_light_grey;
$theme-colors: (
  'primary_red': #e00000,
  'primary_blue': #001996,
  'secondary_light_blue': #00a2c7,
  'secondary_grey': #495057,
  'secondary_orange': #ffa300,
  'secondary_yellow': #fbdd40,
  'secondary_green': #61a30a,
  'secondary_magenta': #c800a1,
  'secondary_black': #000000,
  'web_yellow': #f8c81e,
  'web_light_grey': #d3d3d3,
  'web_lighter_grey': #f5f6f6,
  'web_dark_grey': #495057,
  'web_grey': #c0c0c0,
  'web_text_black': #111111,
  'web_text_light': #ffffff,
  'text_link': #001996,
  'text_link_hover': $link-hover-color,
);

$tooltip-bg: map.get($theme-colors, 'secondary_grey');
$tooltip-arrow-height: 7.99px;
$tooltip-arrow-width: 16.56px;
$tooltip-font-size: 12px;
$card-border-radius: 8px 0px;
$card-inner-border-radius: 7px 0px; // bootstrap calculates this using the formula below
// $card-inner-border-radius: subtract(8px, $card-border-width) 0px;
$card-title-spacer-y: 16px;
@import '~bootstrap/scss/bootstrap.scss';

.tooltip-inner {
  text-align: left !important;
}

.btn-lg {
  width: 137px;
  height: 55px;
  line-height: 24px;
}
.btn-primary {
  text-align: center;
  background-color: $primary_blue;
  color: $web_lighter_grey;
  border-radius: 4px;
  width: 126px;
  height: 50px;
  line-height: 22px;
  box-shadow: none !important;
}
.btn-primary:hover {
  color: $web_text_light;
  background-color: $primary_dark_blue;
  border: 1px solid $primary_dark_blue;
}
.btn-primary:focus {
  background-color: $primary_blue;
  color: $web_lighter_grey;
  border: 2px solid $secondary_light_blue;
}
.btn-primary:disabled {
  color: $web_text_light;
  background-color: $primary_dark_grey;
  opacity: 0.65;
  border: 1px solid $primary_dark_grey;
}
.btn-secondary {
  text-align: center;
  background-color: $web_text_light;
  color: $primary_blue;
  border-radius: 4px;
  border: 1px solid $primary_blue;
  width: 126px;
  height: 50px;
  line-height: 22px;
  box-shadow: none !important;
}
.btn-secondary:focus {
  border: 2px solid $primary_blue;
  background-color: $web_text_light;
  color: $primary_blue;
}
.btn-secondary:hover {
  background-color: $web_text_light;
  color: $link-hover-color;
  border: 1px solid $link-hover-color;
}
.btn-secondary:disabled {
  color: $secondary_light_grey;
  background-color: $primary_white;
  opacity: 0.65;
  border: 1px solid $secondary_light_grey;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  color: $primary_blue;
  background-color: $primary_white;
  border: 2px solid $primary_blue;
}

.card {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.btn-link {
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
  vertical-align: inherit;
  font-weight: inherit;
}
.btn-link:hover {
  text-decoration-thickness: 2px;
}

.card-body {
  padding: 8px 12px;
}

.form-control {
  height: 55px;
  line-height: 24px;
  box-sizing: border-box;
  border-radius: 4px !important;
  background-color: $web_text_light;
  border: 1px solid $web_dark_grey;
  margin-bottom: 10px;
  box-shadow: none;
}
.form-control[type='text'] {
  width: 295px;
}
.form-control:focus {
  border: 2px solid map.get($theme-colors, 'primary_blue');
  //   background: map.get($theme-colors, "web_text_light");
  box-shadow: none;
}
.form-control:invalid + focus {
  box-shadow: none;
  border: 2px solid map.get($theme-colors, 'primary_red');
}
.form-control:invalid {
  box-shadow: none;
}
.invalid-feedback {
  text-align: left;
  color: $invalid_feedback;
}
.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: $invalid_feedback;
}
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: $secondary_black;
}
.was-validated .form-check-input:valid ~ .form-check-label {
  color: $secondary_black;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  border-color: $primary_blue;
  background-color: $primary_blue;
}

.carousel {
  outline: 3px solid $light_shade_primary_blue;
}

.carousel-indicators [data-bs-target] {
  background-color: $primary_blue;
}

/* Form-Radio Buttons Styles. */
.form-check-input[type='radio'] {
  border: 2px solid $secondary_light_grey;
}
.form-check-input:checked[type='radio'] {
  border: 2px solid $primary_blue;
  outline: 3px solid $primary_blue;
  outline-offset: -10px;
  box-shadow: 0 0 5px 4px rgb(59 113 202 / 25%);
  background-image: none;
  background-color: $primary_white;
}
/* End of Form-Radio Buttons Styles. */

.table {
  margin-bottom: 0px;
}
